import FaqCard from "../AllCards/FaqCard";
import StartProjectCard from "../AllCards/StartProjectCard";
import KeyPoints from "../KeyPoints";
import OurClient from "../OurClient";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";

function DigitalMarketingDetail(){
    return(
       <>
       <AllServicesHeader/>
       <section className="digi_container">
            <div className="container">
                <div className="digitalBox">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="totla_click">
                        <div className="to_items">
                            <span>Total click</span>
                            <h2>3.9k</h2>
                        </div>
                        <div className="to_items">
                            <span>Total Imperessions</span>
                            <h2>65.1k</h2>
                        </div>
                        <div className="to_items">
                            <span>Average CTR</span>
                            <h2>5.7%</h2>
                        </div>
                        <div className="to_items">
                            <span>Average Position</span>
                            <h2>6.2</h2>
                        </div>
                    </div>
                        </div>
                        <div className="col-md-6">
                        <div className="digi_msg">
                       <div className="icon1"><img src="./images/cmnt.svg" alt="" /></div>
                        <h1>Digital Marketing</h1>
                        <p>We are here to help you grow your business revenue. We have unique marketing solutions for your unique business needs.</p>
                        <div className="icon2"><img src="./images/draw.svg" alt="" /></div>
                        <div className="icon3"><img src="./images/girls.svg" alt="" /></div> 
                    </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
       <section className="traffic_sec">
        <div className="container">
            <div className="row">
                <div className="col-md-6 my-auto">
                    <h3>Get ready for more traffic, more prospects, and more business!</h3>
                    <p>With our digital marketing services, we’ll help you build a great online presence which is a gateway for your customers wandering online. It’s about the right time, the right place to target the right customers.</p>
                </div>
                <div className="col-md-6 text-end"><img src="./images/traffic.svg" className="img-fluid" alt="" /></div>
            </div>
        </div>
       </section>
        <KeyPoints title="Key point for success in Digital Marketing" desc="There’s a huge spectrum of tactics and assets when it comes to digital marketing. While you are still budding, handling eCommerce store and marketing on the side could be a lot to take on. You might end up spilling it all and losing the business. We don’t want this for our customers so, we are here as your helping hand."/>
       <section className="">
        <div className="container">
        <div className="row">
                <div className="col-md-6"><img src="./images/mobile_app.png" className="img-fluid" alt="" /></div>
                <div className="col-md-6 my-auto">
                    <div className="cnt">
                        <h3>How can Webkul help your business?</h3>
                        <p>We work towards being a part of your success story by ensuring your brand visibility to the targeted audience. It’s not just SEO…it’s a lot more than that.</p>
                        <p>We shape business goals oriented campaigns uniquely tailored for your unique business needs. Reach-based brand awareness campaigns primarily focused on creating CTRs. Moreover, we’ll help you to target specific demographics validating ROI over Profits.</p>
                        <p>We identify your goals & built an attribution model which will include digital touchpoints appearing in the customer retention process. Further, this model will help you understand the ROI for individual marketing campaigns. Also, including A/B testing for lead validation.</p>
                    </div>
                </div>
            </div>
            <div className="row pt-lg-5">
                <div className="col-md-6">
                    <div className="cnt pe-5">
                        <h3>Search Engine Marketing [SEM/PPC/Google Ads]</h3>
                        <p>Search Engine Marketing (SEM) is one of the most productive ways to grow any business in this competitive market.</p>
                        <p>It is the best practice for marketing any business using paid advertisements. It will appear on SERPs.</p>
                        <p>Place your unique selling proposition in front of audiences who are most likely to convert when they click on your ads.</p>
                        <p>SEM entails the use of pay per click (PPC) listings and advertisements. Thus, setting up PPC campaigns and activities is the way to get leads.</p>
                    </div>
                </div>
                <div className="col-md-6"><img src="./images/img2.png" className="img-fluid" alt="" /></div>
            </div>
        </div>
       </section>
       <FaqCard/>
       <OurClient/>
       <StartProjectCard/>
       </>
    )
}

export default DigitalMarketingDetail;