import React, { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

export default function StartProject(props) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const costing = [
    {
      key: 1,
      type: "INR",
      range: [
        "Up to 10,000",
        "10,000-50,000",
        "50,000-1,00,000",
        "1,00,000-10,00,000",
        "10,00,000-1 Crore",
        "Above 1 Crore",
      ],
    },
    {
      key: 2,
      type: "USD",
      range: [
        "Up to 100",
        "100-1,000",
        "1,000-10,000",
        "10,000-100,000",
        "100,000-1,000,000",
        "Above 1,000,000",
      ],
    },
    {
      key: 3,
      type: "EUR",
      range: [
        "Up to 100",
        "100-1,000",
        "1,000-10,000",
        "10,000-100,000",
        "100,000-1,000,000",
        "Above 1,000,000",
      ],
    },
    {
      key: 4,
      type: "GBP",
      range: [
        "Up to 100",
        "100-1,000",
        "1,000-10,000",
        "10,000-100,000",
        "100,000-1,000,000",
        "Above 1,000,000",
      ],
    },

    {
      key: 5,
      type: "CAD",
      range: [
        "Up to 100",
        "100-1,000",
        "1,000-10,000",
        "10,000-100,000",
        "100,000-10,00,000",
        "Above 10,00,000",
      ],
    },
  ];

  const services = [
    "Software Development",
    "Website Development",
    "Mobile App Development",
    "Digital Marketing",
    "UI/UX Design",
    "Software Security",
    "Web Hosting",
    "CRM system",
  ];
  const dropdownRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [checkedServices, setCheckedServices] = useState({});
  const [selectedType, setSelectedType] = useState(costing[0].type);
  const [selectedRange, setSelectedRange] = useState(costing[0].range);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    mobile: "",
    projectDescription: "",
    budget: "",
    descriptionpdf: null,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    const selectedCosting = costing.find((item) => item.type === selectedType);
    setSelectedType(selectedType);
    setSelectedRange(selectedCosting.range);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      descriptionpdf: e.target.files[0],
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "", email: "", mobile: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain letters and spaces";
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile number is required";
      valid = false;
    }

    if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const budget = `${selectedType} ${formData.budget}`;
    const selectedServices = Object.keys(checkedServices).filter(
      (service) => checkedServices[service]
    );

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64pdf = reader.result.split(",")[1];

      const data = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        countryCode: formData.countryCode,
        services: selectedServices,
        projectDescription: formData.projectDescription,
        budget: budget,
        descriptionpdf: base64pdf,
      };

      try {
        const response = await axios.post(`${apiUrl}/pyndertech/submit`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log(response.data.status);
        if (response.data) {
          navigate(-1);
          toast.success("Form Submitted SuccessFully !", {
            position: "top-center",
          });
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    };

    reader.readAsDataURL(formData.descriptionpdf);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setCheckedServices((prevServices) => ({
      ...prevServices,
      [value]: checked,
    }));
  };

  return (
    <section className="project-form-parent-container start-projectform">
      <div className="container startproject-container">
        <div className="form-container formcontainer-startproject mt-3 mb-2">
        <IoMdClose onClick={handleGoBack} className="project-close-btn" />

          <h2 className="text-center">Start A Project</h2>
          <form className="project-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={validateForm}
                placeholder="Enter your name"
                required
              />
              {errors.name && (
                <div className="error-message">{errors.name}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={validateForm}
                placeholder="Enter your email"
                required
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
            </div>

            <div className="form-group btn-group services-dropdown">
              <label htmlFor="services">Choose Services</label>
              <button
                className="dropdown-inable form-control"
                onClick={(e) => {
                  e.preventDefault();
                  setDropdown(!dropdown);
                }}
              >
                Services <IoMdArrowDropdown />
              </button>
              {dropdown && (
                <div className="footer-dropdown" ref={dropdownRef}>
                  {services.map((item, i) => (
                    <div className="checkbox" key={i}>
                      <input
                        type="checkbox"
                        value={item}
                        checked={!!checkedServices[item]}
                        onChange={handleCheckboxChange}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="countryCode">Enter Country Code</label>
              <input
                type="text"
                className="form-control"
                id="countryCode"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                placeholder="Enter Country Code"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile">Enter Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                onBlur={validateForm}
                placeholder="Enter Phone Number"
                required
              />
              {errors.mobile && (
                <div className="error-message">{errors.mobile}</div>
              )}
            </div>
            <div className="form-group ">
              <label htmlFor="descriptionpdf">Attach PDF</label>
              <input
                type="file"
                className="form-control"
                id="descriptionpdf"
                name="descriptionpdf"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="w-100">
              <label htmlFor="budget">Budget</label>
              <div className="costing-container ">
                <select
                  className="form-control item-1"
                  name="budgetType"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  {costing.map((item) => (
                    <option key={item.key} value={item.type}>
                      {item.type}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control item-1"
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                >
                  {selectedRange.map((range, i) => (
                    <option key={i} value={range}>
                      {range}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group w-100">
              <label htmlFor="projectDescription">Project Description</label>
              <textarea
                className="form-control"
                id="projectDescription"
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleChange}
                placeholder="Enter your project description"
                required
              />
            </div>

            <button type="submit" className="btn btn-primary submitbtn-startproject">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}



// export default function StartProject(props) {
//   const navigate = useNavigate();
//   const apiUrl = process.env.REACT_APP_BACKEND_URL;

//   const costing = [
//         {
//           key: 1,
//           type: "INR",
//           range: [
//             "Up to 10,000",
//             "10,000-50,000",
//             "50,000-1,00,000",
//             "1,00,000-10,00,000",
//             "10,00,000-1 Crore",
//             "Above 1 Crore",
//           ],
//         },
//         {
//           key: 2,
//           type: "USD",
//           range: [
//             "Up to 100",
//             "100-1,000",
//             "1,000-10,000",
//             "10,000-100,000",
//             "100,000-1,000,000",
//             "Above 1,000,000",
//           ],
//         },
//         {
//           key: 3,
//           type: "EUR",
//           range: [
//             "Up to 100",
//             "100-1,000",
//             "1,000-10,000",
//             "10,000-100,000",
//             "100,000-1,000,000",
//             "Above 1,000,000",
//           ],
//         },
//         {
//           key: 4,
//           type: "GBP",
//           range: [
//             "Up to 100",
//             "100-1,000",
//             "1,000-10,000",
//             "10,000-100,000",
//             "100,000-1,000,000",
//             "Above 1,000,000",
//           ],
//         },
    
//         {
//           key: 5,
//           type: "CAD",
//           range: [
//             "Up to 100",
//             "100-1,000",
//             "1,000-10,000",
//             "10,000-100,000",
//             "100,000-10,00,000",
//             "Above 10,00,000",
//           ],
//         },
//       ];
    
//       const services = [
//         "Software Development",
//         "Website Development",
//         "Mobile App Development",
//         "Digital Marketing",
//         "UI/UX Design",
//         "Software Security",
//         "Web Hosting",
//         "CRM system",
//       ];
//   const dropdownRef = useRef(null);
//   const [dropdown, setDropdown] = useState(false);
//   const [checkedServices, setCheckedServices] = useState({});
//   const [selectedType, setSelectedType] = useState(costing[0].type);
//   const [selectedRange, setSelectedRange] = useState(costing[0].range);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     countryCode: "",
//     mobile: "",
//     projectDescription: "",
//     budget: "",
//     descriptionpdf: null,
//   });

//   const [errors, setErrors] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//   });

//   const [currentStep, setCurrentStep] = useState(0);

//   const handleTypeChange = (event) => {
//     const selectedType = event.target.value;
//     const selectedCosting = costing.find((item) => item.type === selectedType);
//     setSelectedType(selectedType);
//     setSelectedRange(selectedCosting.range);
//   };

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       descriptionpdf: e.target.files[0],
//     }));
//   };

//   const validateForm = () => {
//     let valid = true;
//     const newErrors = { name: "", email: "", mobile: "" };

//     if (!formData.name.trim()) {
//       newErrors.name = "Name is required";
//       valid = false;
//     }

//     if (!/^[a-zA-Z ]+$/.test(formData.name)) {
//       newErrors.name = "Name can only contain letters and spaces";
//       valid = false;
//     }

//     if (!formData.email.trim()) {
//       newErrors.email = "Email is required";
//       valid = false;
//     }

//     if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
//       newErrors.email = "Invalid email address";
//       valid = false;
//     }

//     if (!formData.mobile.trim()) {
//       newErrors.mobile = "Mobile number is required";
//       valid = false;
//     }

//     if (!/^\d{10}$/.test(formData.mobile)) {
//       newErrors.mobile = "Mobile number must be 10 digits";
//       valid = false;
//     }

//     setErrors(newErrors);
//     return valid;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     const budget = `${selectedType} ${formData.budget}`;
//     const selectedServices = Object.keys(checkedServices).filter(
//       (service) => checkedServices[service]
//     );

//     const reader = new FileReader();
//     reader.onloadend = async () => {
//       const base64pdf = reader.result.split(",")[1];

//       const data = {
//         name: formData.name,
//         email: formData.email,
//         mobile: formData.mobile,
//         countryCode: formData.countryCode,
//         services: selectedServices,
//         projectDescription: formData.projectDescription,
//         budget: budget,
//         descriptionpdf: base64pdf,
//       };

//       try {
//         const response = await axios.post(`${apiUrl}/pyndertech/submit`, data, {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });

//         console.log(response.data.status);
//         if (response.data) {
//           navigate(-1);
//           toast.success("Form Submitted SuccessFully !", {
//             position: "top-center",
//           });
//         }
//       } catch (error) {
//         console.error("Error submitting the form:", error);
//       }
//     };

//     reader.readAsDataURL(formData.descriptionpdf);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdown(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleCheckboxChange = (e) => {
//     const { value, checked } = e.target;
//     setCheckedServices((prevServices) => ({
//       ...prevServices,
//       [value]: checked,
//     }));
//   };

//   const nextStep = () => {
//     if (currentStep < 3) {
//       setCurrentStep(currentStep + 1);
//     }
//   };

//   const previousStep = () => {
//     if (currentStep > 0) {
//       setCurrentStep(currentStep - 1);
//     }
//   };

//   return (
//     <section className="project-form-parent-container">
//       <div className="container">
//         <div className="form-container mt-3 mb-2">
//           <IoMdClose onClick={handleGoBack} className="project-close-btn" />
//           <h2 className="text-center">Start A Project</h2>
//           <form className="project-form" onSubmit={handleSubmit}>
//             {currentStep === 0 && (
//               <>
//                 <div className="form-group">
//                   <label htmlFor="name">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     onBlur={validateForm}
//                     placeholder="Enter your name"
//                     required
//                   />
//                   {errors.name && (
//                     <div className="error-message">{errors.name}</div>
//                   )}
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="email">Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     onBlur={validateForm}
//                     placeholder="Enter your email"
//                     required
//                   />
//                   {errors.email && (
//                     <div className="error-message">{errors.email}</div>
//                   )}
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="mobile">Enter Phone Number</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="mobile"
//                     name="mobile"
//                     value={formData.mobile}
//                     onChange={handleChange}
//                     onBlur={validateForm}
//                     placeholder="Enter Phone Number"
//                     required
//                   />
//                   {errors.mobile && (
//                     <div className="error-message">{errors.mobile}</div>
//                   )}
//                 </div>
//               </>
//             )}
//             {currentStep === 1 && (
//               <>
//                 <div className="form-group">
//                   <label htmlFor="countryCode">Enter Country Code</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="countryCode"
//                     name="countryCode"
//                     value={formData.countryCode}
//                     onChange={handleChange}
//                     placeholder="Enter Country Code"
//                     required
//                   />
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="projectDescription">Project Description</label>
//                   <textarea
//                     className="form-control"
//                     id="projectDescription"
//                     name="projectDescription"
//                     value={formData.projectDescription}
//                     onChange={handleChange}
//                     placeholder="Enter your project description"
//                     required
//                   />
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="descriptionpdf">Attach PDF</label>
//                   <input
//                     type="file"
//                     className="form-control"
//                     id="descriptionpdf"
//                     name="descriptionpdf"
//                     accept=".pdf,.doc,.docx"
//                     onChange={handleFileChange}
//                     required
//                   />
//                 </div>
//               </>
//             )}
//             {currentStep === 2 && (
//               <>
//                 <div className="form-group btn-group services-dropdown">
//                   <label htmlFor="services">Choose Services</label>
//                   <button
//                     className="dropdown-inable form-control"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setDropdown(!dropdown);
//                     }}
//                   >
//                     Services <IoMdArrowDropdown />
//                   </button>
//                   {dropdown && (
//                     <div className="footer-dropdown" ref={dropdownRef}>
//                       {services.map((item, i) => (
//                         <div className="checkbox" key={i}>
//                           <input
//                             type="checkbox"
//                             value={item}
//                             checked={!!checkedServices[item]}
//                             onChange={handleCheckboxChange}
//                           />
//                           {item}
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </>
//             )}
//             {currentStep === 3 && (
//               <>
//                 <div className="form-group">
//                   <label htmlFor="budget">Budget</label>
//                   <div className="budget-dropdown">
//                     <select
//                       className="form-control"
//                       value={selectedType}
//                       onChange={handleTypeChange}
//                     >
//                       {costing.map((item, i) => (
//                         <option key={i} value={item.type}>
//                           {item.type}
//                         </option>
//                       ))}
//                     </select>
//                     <select
//                       className="form-control"
//                       id="budget"
//                       name="budget"
//                       value={formData.budget}
//                       onChange={handleChange}
//                     >
//                       {selectedRange.map((range, i) => (
//                         <option key={i} value={range}>
//                           {range}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 </div>
//               </>
//             )}

//             <div className="form-navigation">
//               {currentStep > 0 && (
//                 <button type="button" onClick={previousStep} className="btn btn-secondary m-3">
//                   Previous
//                 </button>
//               )}
//               {currentStep < 3 && (
//                 <button type="button" onClick={nextStep} className="btn btn-primary">
//                   Next
//                 </button>
//               )}
//               {currentStep === 3 && (
//                 <button type="submit" className="btn btn-primary">
//                   Submit
//                 </button>
//               )}
//             </div>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// }
