import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function AllServicesHeader() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const GetTitleAndDescription = () => {
    let obj = { title: "", dec: "" };
    if (path.includes("website-development")) {
      obj.title = (
        <>
          <h1>Website Development</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              Website development involves creating, building, publishing, and
              maintaining a website for{" "}
            </p>
            <p>
              the world-wide-web (internet) or private network (intranet). Using
              many open-source{" "}
            </p>
            <p>
              available tools, we can develop a website with simple static
              content, or build complex web{" "}
            </p>
            <p>applications, offer services, and run commerce businesses.</p>
          </div>
        </>
      );
    } else if (path.includes("mobile-development")) {
      obj.title = (
        <>
          <h1>Mobile App Development</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              Mobile app development is a dynamic and evolving field that
              requires expertise in{" "}
            </p>
            <p>
              programming, design, usability, and backend integration.
              Successful mobile app development{" "}
            </p>
            <p>
              {" "}
              involves understanding user needs, leveraging platform-specific
              capabilities, and staying{" "}
            </p>
            <p>
              abreast of technological advancements to create innovative and
              impactful applications for{" "}
            </p>
            <p>a global audience.</p>
          </div>
        </>
      );
    } else if (path.includes("crm-system")) {
      obj.title = (
        <>
          <h1>CRM System</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              At Pyndertech, we understand the pivotal role of Customer
              Relationship Management (CRM)
            </p>
            <p>
              in driving business success. Our CRM solutions are designed to
              empower your organization{" "}
            </p>
            <p>
              by streamlining customer interactions, enhancing productivity, and
              fostering{" "}
            </p>
            <p>long-term customer relationships.</p>
          </div>
        </>
      );
    } else if (path.includes("digital-marketing")) {
      obj.title = (
        <>
          <h1>Digital Marketing</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              Digital marketing refers to the use of online channels such as
              websites, social media,{" "}
            </p>
            <p>
              {" "}
              search engines,email, and mobile apps to reach and engage with
              potential customers. It{" "}
            </p>
            <p>
              includes activities such as content marketing, search engine
              optimization (SEO),{" "}
            </p>
            <p>
              pay-per-click advertising (PPC), social media marketing, email
              marketing, and more.{" "}
            </p>
            <p>
              The goal of digital marketing is to drive traffic, generate
              leads,field that continues{" "}
            </p>
            <p>
              to evolve with advancements in technology and changes in consumer
              behavior.{" "}
            </p>
          </div>
        </>
      );
    } else if (path.includes("ui-ux-design")) {
      obj.title = (
        <>
          <h1>UI/UX</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              Pyndertech is a leading UX/UI design and development services
              company, delivering visually
            </p>
            <p>
              compelling and functional designs to clients across the world.
              From website designing, app
            </p>
            <p>
              designing, logo design to PSD conversion services, we believe in
              designing delightful user{" "}
            </p>
            <p> experiences for your brand's online identity. </p>
          </div>
        </>
      );
    } else if (path.includes("web-hosting")) {
      obj.title = (
        <>
          <h1>Web Hosting</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              At Pyndertech, we offer reliable and secure web hosting solutions
              tailored to meet the needs
            </p>
            <p>
              of businesses and organizations of all sizes. Whether you’re
              launching a new website or
            </p>
            <p>
              looking to migrate an existing one, our comprehensive web hosting
              services ensure your
            </p>
            <p>online presence is fast, secure, and always accessible.</p>
          </div>
        </>
      );
    } else if (path.includes("software-development")) {
      obj.title = (
        <>
          <h1>Software Development</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            <p>
              Software development is the systematic process of designing,
              coding, testing, and
            </p>
            <p>
              maintaining software to meet specific requirements. It involves a
              blend of creativity and{" "}
            </p>
            <p>
              {" "}
              engineering discipline to develop applications that solve
              problems, automate tasks, or provide{" "}
            </p>
            <p>
              {" "}
              entertainment.From mobile apps to enterprise systems, software
              development spans various{" "}
            </p>
            <p>
              {" "}
              domains, each with its own set of technologies, methodologies, and
              best practices.
            </p>
          </div>
        </>
      );
    } else if (path.includes("software-security")) {
      obj.title = (
        <>
          <h1>Software Security</h1>
        </>
      );
      obj.dec = (
        <>
          <div className="description">
            .
            <p>
              {" "}
              At Pyndertech, we prioritize the security of your digital assets
              with our comprehensive
            </p>
            <p>
              software security services. Whether you're developing new software
              or maintaining existing
            </p>
            <p>
              applications, our solutions are designed to safeguard against
              evolving cyber threats{" "}
            </p>
            <p> and vulnerabilities</p>
          </div>
        </>
      );
    }

    return obj;
  };

  console.log(path);
  return (
    <section className="ser_header">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <figure>
            <img src="./images/left_graphic.png" alt="" />
          </figure>
          {GetTitleAndDescription().title}
          <figure>
            <img src="./images/right_graphic.png" alt="" />
          </figure>
        </div>
        {GetTitleAndDescription().dec}
        <div className="text-center">
          <Link to="/start-project">Start a Project</Link>
        </div>
      </div>
    </section>
  );
}
export default AllServicesHeader;
