import React from "react";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import StartProjectCard from "../AllCards/StartProjectCard";
import FaqCard from "../AllCards/FaqCard";
import TechInfo from "../TechInfo";

function WebsiteDetail(){
    const data=[
        {id:"1", title:"Target Potential Users", icon:"./images/target_icon.svg",dec:"A truly effective user centric approach that comes from knowing your target audience. If you have a product to sell or a service to offer, then you have to know who your target audience is."},
        {id:"2", title:"Deliver High Performance", icon:"./images/deliver_icon.svg",dec:"One of the biggest predictors of mobile app success is its performance. It is one of the factors of mobile app quality that has to do with how the app will behave when experiencing certain load sizes."},
        {id:"3", title:"Push Quick Updates", icon:"./images/updates_icon.svg",dec:"Frequently upgrade targets performance issues. Updated apps address all the non-security related bugs, which makes them less susceptible to crashing which helps you to stand out from the crowd."},
        {id:"4", title:"Increase Brand Exposure", icon:"./images/inc_icon.svg",dec:"Sending a well considered, relevant, and appropriate notification to your users will increase brand exposure. It helps you to re-target and re-engage your users."},
        {id:"5", title:"Bring Trust & Loyalty", icon:"./images/trust_icon.svg",dec:"Integrated security and scalability features will efficiently handle more and more requests. Above all, it pushes the trust and loyalty of app users. Also, caters to your ability to maintain the app."},
        {id:"6", title:"Ensure Delightful Experience", icon:"./images/ensure_icon.svg",dec:"Redefine the way of assisting developing a strong connection with potential customers. Your company feels more real to users. A unique and engaging user experience is enough to benefit them in"}
    ]

    return(
        <>
        <AllServicesHeader/>
        <section className="cloth_banner">
            <div className="container">
                <div className="responsiveImage d-flex">
                    <div><img src="./images/cloth.png" className="img-fluid" alt="" /></div>
                    <div><img src="./images/cloth-mob.png" className="img-fluid" alt="" /></div>
                    <div><img src="./images/cloth-tab.png" className="img-fluid" alt="" /></div>
                </div>
            </div>
        </section>
        <section className="website_dev">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website">
                            <h2>Why should I care about website development?</h2>
                            <p>With the evolution of web and internet availability, businesses drive the web development industry. Every company uses websites to advertise, promote, and do business with its customers. With dynamic web content, we can share and exchange up-to-date information in real-time.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/stall-design.png" className="img-fluid shadow_img" alt="" /></div>
                </div>
            </div>
        </section>
        <section className="website_dev">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website">
                            <h2>Benefits of Website Development</h2>
                            <p>There are many benefits when you hire a custom website development company:</p>
                            <ul className="list_items">
                                <li>Reduces costs, so that you can focus on your core business activities.</li>
                                <li>A better-looking website with professional design.</li>
                                <li>Expert knowledge of modern web technologies.</li>
                                <li>Customized website design and development.</li>
                                <li>Quality work that matches and uplifts your brand.</li>
                                <li>Responsive web design to give the same browsing experience to different devices.</li>
                                <li>Search engine visibility and discoverability.</li>
                                <li>Security checks to protect valuable data.</li>
                                <li>Speed and performance optimization to handle the traffic.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/benefit.svg" className="img-fluid shadow_img bg_gry" alt="" /></div>
                </div>
            </div>
        </section>
        <section className="web_logo">
            <div className="container">
                <ul>
                    <li>
                        <figure><img src="./images/cart_icon.svg" alt="" /></figure>
                        <figcaption>E-commerce Website</figcaption>
                    </li>
                    <li>
                        <figure><img src="./images/blog_icon.svg" alt="" /></figure>
                        <figcaption>Blogging Website</figcaption>
                    </li>
                    <li>
                        <figure><img src="./images/education_icon.svg" alt="" /></figure>
                        <figcaption>Educational Website</figcaption>
                    </li>
                    <li>
                        <figure><img src="./images/b2b_icon.svg" alt="" /></figure>
                        <figcaption>B2B Website</figcaption>
                    </li>
                </ul>
            </div>
        </section>
        <section className="boost_main">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 title_bost mb-lg-5">
                        <h2>Boost conversion and delight users with website development</h2>
                        <p>Website development is tremendously changing the business industry. To stand out in the high pace industry having an online presence is highly effective. Website development is beneficial for every business because it gives you the flexibility to control and push your brand value to new heights.</p>
                    </div>
                </div>
                <div className="row g-4">
                    {data?.map((ele)=>{
                     return(
                         <div className="col-md-6 col-lg-4">
                            <div className="bost_box">
                                <figure><img src={ele?.icon} alt="" /></figure>
                                <h3>{ele?.title}</h3>
                                <p>{ele?.dec}</p>
                            </div>
                         </div>
                     )   
                    })}
                </div>
            </div>
        </section>
        <section className="web_logo">
            <div className="container">
                    <div className="product">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Is your product hard to use?</h3>
                                <p>Although many open source tools and technologies are for web development such as LAMP (Linux, Apache, MySQL, PHP) stack, WYSIWYG editors, CMS (Content Management </p>
                            </div>
                            <div className="col-lg-6 text-lg-end"><img src="./images/produt_img.svg" className="img-fluid" alt="" /></div>
                        </div>
                    </div>
            </div>
        </section>
        <section className="boost_main">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 title_bost mb-lg-5">
                        <h2>Scale your business with Pyndertech web development services</h2>
                        <p>Although many open-source tools and technologies are for web development such as LAMP (Linux, Apache, MySQL, PHP) stack, WYSIWYG editors, CMS (Content Management System) like WordPress, Microsoft Visual Studio. But you still require the professional knowledge and expertise of HTML, CSS, and JavaScript, and should also know some basic programming languages to work with.</p>
                    </div>
                </div>
                <div className="row pb-lg-5 pb-3">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website create_website_2 ">
                            <h2>E-commerce Website</h2>
                            <p>We’ve expertise in creating world-class extensions and multi-vendor marketplace for popular eCommerce frameworks like Magento, PrestaShop, OpenCart, WooCommerce, and many others.</p>
                            <p>We have developed more than 2000+ extensions for popular eCommerce extensions in the last 12+ years, which brought versatile industrial expertise to our team.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/ecom_website.png" className="img-fluid shadow_img" alt="" /></div>
                </div>
                <div className="row pb-lg-5 pb-3">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website create_website_2 ">
                            <h2>Blogging Website</h2>
                            <p>Every company and a brand has a blog section to promote its products and services with posts, articles, and other types of content to share with customers. We can help you in creating a blog section for your existing website or create a custom blogging website for content promotion and marketing.</p>
                            <p>With expert knowledge and experience in working with all the popular content management systems. We develop custom solutions for WordPress, Joomla, and other CMS frameworks.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/blog_website.png" className="img-fluid shadow_img" alt="" /></div>
                </div>
                <div className="row pb-lg-5 pb-3">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website create_website_2 ">
                            <h2>Booking System</h2>
                            <p>Booking is one of the emerging verticals. We have created open-source reservation frameworks like QLO Apps and Booking Commerce platform. We’ve deep expertise and top-notch development skills to create a booking or reservation system as per your requirement.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/booking_website.png" className="img-fluid shadow_img" alt="" /></div>
                </div>
                <div className="row pb-lg-5 pb-3">
                    <div className="col-lg-6 my-auto">
                        <div className="create_website create_website_2 ">
                            <h2>Educational Website</h2>
                            <p>We can create a web-based system to sell and deliver online courses for educational purposes. With a custom-built platform, you can provide educational materials, content, videos, and files to students and institutions.</p>
                        </div>
                    </div>
                    <div className="col-lg-6"><img src="./images/edu_website.png" className="img-fluid shadow_img" alt="" /></div>
                </div>

            </div>
        </section>
        {/* <section className="deal_top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 title_bost mb-lg-5">
                            <h2>We deal in top technologies to bring maximum value to your business.</h2>
                            <p>Although many open source tools and technologies are for web development such as LAMP (Linux, Apache, MySQL, PHP) stack, WYSIWYG editors, CMS (Content Management System) like WordPress, and Microsoft Visual Studio. But you still require the professional knowledge and expertise of HTML, CSS, and JavaScript, and should also know some basic programming languages to work with.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <ul className="techLogo">
                                {techLogo.map((ele)=>{
                                    return(
                                        <li>
                                            <figure><img src={ele?.logo} alt="" /></figure>
                                            <figcaption>{ele?.title}</figcaption>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
        </section> */}
        <TechInfo  title="We deal in top technologies to bring maximum value to your business." desc="Although many open source tools and technologies are for web development such as LAMP (Linux, Apache, MySQL, PHP) stack, WYSIWYG editors, CMS (Content Management System) like WordPress, and Microsoft Visual Studio. But you still require the professional knowledge and expertise of HTML, CSS, and JavaScript, and should also know some basic programming languages to work with."/>

        <FaqCard/>
        <StartProjectCard/>
        </>
    )
}

export default WebsiteDetail;