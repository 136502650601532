import React from "react"
function KeyPoints(props){
    const data=[
        {id:"1",title:"Lack of centralization",desc:"It's difficult to make key judgments when your customer data is scattered between excel sheets, business cards, and notes taken during client meetings. It takes far too much time and effort to collect and interpret the data."},
        {id:"1",title:"Communication breakdown",desc:"Marketing, which nurtures the lead, and Sales, which closes the transaction, must work together. This lack of information flow frequently harms both sides' performances because neither is aware of what the other is doing."},
        {id:"1",title:"Turnover costs time and money",desc:"Marketing, which nurtures the lead, and Sales, which closes the transaction, must work together. This lack of information flow frequently harms both sides' performances because neither is aware of what the other is doing."},
        {id:"1",title:"Lack of knowledge",desc:"This is a subset of the first indication. Salespeople would struggle to keep track of every detail as their client base increased in the absence of a single repository for their customer data."},
        {id:"1",title:"Inability to close deals on the move",desc:"When you have sales agents out in the field, it's tough for them to keep everyone in the loop up to speed on the status of a deal or provide documentation like sales orders and invoices."},
        {id:"1",title:"Poor customer satisfaction",desc:"Scattered data makes it difficult to see trends and personalize interactions with clients. This results in all consumers being treated the same, which risks pushing them away to your rivals for a better experience."}
    ]
    return(
        
        <>
        <section className="key_sec">
            <div className="container">
                <div className="key_title">
                    <h3>{props.title}</h3>
                    <p>{props.desc}</p>
                </div>
                <div className="key_main">
                    {data.map((ele)=>{
                        return(
                            <div className="key_items">
                                <h3>{ele?.title}</h3>
                                <p>{ele?.desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
       </section>
        </>
    )
}

export default KeyPoints;