import FaqCard from "../AllCards/FaqCard";
import StartProjectCard from "../AllCards/StartProjectCard";
import Gallery from "../Gallery";
import OurClient from "../OurClient";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import TechInfo from "../TechInfo";

function UIUXDetail(){
    const data=[
        {id:"1", title:"Web App Design", icon:"./images/target_icon.svg",dec:"A truly effective user centric approach that comes from knowing your target audience. If you have a product to sell or a service to offer, then you have to know who your target audience is."},
        {id:"2", title:"Mobile App Design Services", icon:"./images/deliver_icon.svg",dec:"One of the biggest predictors of mobile app success is its performance. It is one of the factors of mobile app quality that has to do with how the app will behave when experiencing certain load sizes."},
        {id:"3", title:"Branding", icon:"./images/updates_icon.svg",dec:"Frequently upgrade targets performance issues. Updated apps address all the non-security related bugs, which makes them less susceptible to crashing which helps you to stand out from the crowd."},
        {id:"4", title:"PWA Design", icon:"./images/inc_icon.svg",dec:"Sending a well considered, relevant, and appropriate notification to your users will increase brand exposure. It helps you to re-target and re-engage your users."},
        {id:"5", title:"Headless Design", icon:"./images/trust_icon.svg",dec:"Integrated security and scalability features will efficiently handle more and more requests. Above all, it pushes the trust and loyalty of app users. Also, caters to your ability to maintain the app."},
        {id:"6", title:"Data Driven Design", icon:"./images/ensure_icon.svg",dec:"Redefine the way of assisting developing a strong connection with potential customers. Your company feels more real to users. A unique and engaging user experience is enough to benefit them in"}
    ]
    return(
        <>
        <AllServicesHeader/>
        <section className="space_top">
            <div className="container">
                <div className="ux_box">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mobile_bg">
                                <img src="./images/music.svg" className="img-fluid" alt="" />
                                <img src="./images/music.svg" className="img-fluid d-none d-xl-block" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ui_cnt">
                                <h2>UI/UX design agency,molding ideas into business</h2>
                                <p>Website development involves creating, building, publishing, and maintaining a website for the world-wide-web (internet) or private network</p>
                                <img src="./images/graphic_icon.svg" className="img-fluid"  alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="space_top">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 my-auto">
                    <div class="create_website">
                        <h2>Get ready for more attractive, more prospects, Designs!</h2>
                        <p>Let our creativity inspire your imagination. Pyndertech offers creative design solutions that enhance user experience and increase the value of your brand. Our analysts and UX/UI designers work with you to deliver custom designs in various forms that match your unique business requirements. Being one of the leading UI UX design services company, we deliver highly usable and intuitive design services for websites, mobile apps, brand logos, eCommerce stores, enterprise software and wearable applications. Let us help you utilize the “power of UI UX design services” to create experiences that your customers love!</p>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="green_mobile_bg">
                                <img src="./images/green_mobile.svg" className="img-fluid" alt="" />
                                <img src="./images/green_mobile.svg" className="img-fluid d-none d-xl-block" alt="" />
                            </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="space_top">
            <div className="container">
                <ul className="d-flex justify-content-between p-lg-5 grap_sec">
                    <li><img src="./images/ui-1.svg" alt="" /></li>
                    <li><img src="./images/ui-2.svg" alt="" /></li>
                    <li><img src="./images/ui-3.svg" alt="" /></li>
                    <li><img src="./images/ui-4.svg" alt="" /></li>
                    <li><img src="./images/ui-5.svg" alt="" /></li>
                </ul>
            </div>
        </section>
        <section>
            <Gallery/>

        </section>
        <section className="boost_main">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 title_bost mb-lg-4">
                        <h2>How we can help you ?</h2>
                    </div>
                </div>
                <div className="row g-4">
                    {data?.map((ele)=>{
                     return(
                         <div className="col-md-6 col-lg-4">
                            <div className="bost_box bg-white shadow-none">
                                <figure><img src={ele?.icon} alt="" /></figure>
                                <h3>{ele?.title}</h3>
                                <p>{ele?.dec}</p>
                            </div>
                         </div>
                     )   
                    })}
                </div>
            </div>
        </section>
        <TechInfo title="UI/UX" desc="Choosing a development approach is subjective and requires consideration as per the desired user experience, resources, app features, and project timeline. You don’t need any coding experience to start making your own. We are a team of top-notch full stack android developers that has great hands-on experience with Android app development tools and framework. We build native Android applications on Kotlin and Java languages. Apart from this Dart and React Native is also used for Android app developmentWe have a team of highly skilled UI/UX designers to work for you! Give us a call to discuss your project!."/>
        <FaqCard/>
        <StartProjectCard/>
        <OurClient/>
       
        {/* <section className="space_top">
            <div className="container">
                <ul className="d-flex justify-content-between p-lg-5 grap_sec">
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/15.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/13.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/14.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/12.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/11.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/10.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/09.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/08.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/07.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/06.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/04.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/03.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/02.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/05.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2018/08/01.jpg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2017/05/supply-bc.jpeg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2017/05/mysport-mall.jpeg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2017/05/listopen.jpeg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2017/05/go-campas.jpeg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li> 
               
            <li >
              <figure>
              <img src="https://www.sourcesoftsolutions.com/wp-content/uploads/2017/05/eshop.jpeg" class="img-responsive" width="252" height="142"/>
              </figure>
            </li>
            </ul>
            </div></section> */}

                           
       
        </>
    )
}

export default UIUXDetail;