import React from "react";
import img1 from '../../src/imagemasonry/app-1.png'
import img2 from '../../src/imagemasonry/app-2.svg'
import img3 from '../../src/imagemasonry/app-3.png'
import img4 from '../../src/imagemasonry/app-4.svg'
import img5 from '../../src/imagemasonry/app-5.png'
import img6 from '../../src/imagemasonry/app-6.svg'
import img7 from '../../src/imagemasonry/app-7.svg'
import img8 from '../../src/imagemasonry/app-8.png'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
function Gallery(){
    const images= [img1,img2,img3,img4,img5,img6,img7,img8]
    const items = images.map((image, index) => (
        <img
            key={index}
            // src={`image}${Math.floor(
            //     Math.random() * (300 - 200 + 1) + 200
            //   )}`}
            src={image}
            alt={`Gallery item ${index}`}
            style={{ width: "100%", borderRadius: "8px" }}
        />
    ));
    return(
        <>
        <div className="container">
            <div className="image">
                <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 2, 700: 3, 900: 4 }}>
                    <Masonry> {items}</Masonry>
                </ResponsiveMasonry>
            </div>
      </div>
      </>
    )
}

export default Gallery;