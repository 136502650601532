import logo from "./logo.svg";
import "./App.css";
import "./style.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./Component/Layout/Layout";
import { Route, Routes } from "react-router-dom";
import Technologies from "./Component/Pages/Technologies";
import Careers from "./Component/Pages/Careers";
import About from "./Component/Pages/About";
import Services from "./Component/Pages/Services";
import Blog from "./Component/Pages/Blog";
import Home from "./Component/Pages/Home";
import WebsiteDetail from "./Component/ServicesDetail/WebsiteDetail";
import MobileDetail from "./Component/ServicesDetail/MobileDetail";
import CRMDetail from "./Component/ServicesDetail/CRMDetail";
import DigitalMarketingDetail from "./Component/ServicesDetail/DigitalMarketingDetail";
import UIUXDetail from "./Component/ServicesDetail/UIUXDetail";
import WebHostingDetail from "./Component/ServicesDetail/WebHostingDetail";
import SoftwareDevelopment from "./Component/ServicesDetail/SoftwareDevelopment";
import SoftwareSecurity from "./Component/ServicesDetail/SoftwareSecurity";
import BlogDetail from "./Component/BlogDetail";
import Privacy from "./Component/Pages/Privacy";
import Company from "./Component/Pages/Company";
import StartProject from "./Component/StartProject";
import OurWork from "./Component/OurWork";
import ContactUs from "./Component/ContactUs/Contactus";
import ScrollToTop from "./Component/ScrollToTop";

function App() {
  return (
    // <Routes>
    //   <Route path='/' element={<Layout/>}>
    //     <Route index element={<Home/>}/>
    //     <Route path='/about' element={<About/>}/>
    //     <Route path='/services' element={<Services/>}/>
    //     <Route path='/technology' element={<Technologies/>}/>
    //     <Route path='/career' element={<Careers/>}/>
    //     <Route path='/blog' element={<Blog/>}/>
    //     <Route path='/websiteDetail' element={<WebsiteDetail/>}/>
    //     <Route path='/mobileDetail' element={<MobileDetail/>}/>
    //     <Route path='/crmDetail' element={<CRMDetail/>}/>
    //     <Route path="/digitalMarketingDetail" element={<DigitalMarketingDetail/>}/>
    //     <Route path="/uiDetail" element={<UIUXDetail/>}/>
    //     <Route path="/webHostingDetail" element={<WebHostingDetail/>}/>
    //     <Route path="/softwaredevelopment" element={<SoftwareDevelopment/>}/>
    //     <Route path="/softwaresecurity" element={<SoftwareSecurity/>}/>
    //     <Route path='/blogdetail' element={<BlogDetail/>}/>
    //   </Route>
    // </Routes>
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact index element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/technology" element={<Technologies />} />
          <Route exact path="/career" element={<Careers />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route
            exact
            path="/website-development"
            element={<WebsiteDetail />}
          />
          <Route exact path="/mobile-development" element={<MobileDetail />} />
          <Route exact path="/crm-system" element={<CRMDetail />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route
            exact
            path="/digital-marketing"
            element={<DigitalMarketingDetail />}
          />
          <Route exact path="/ui-ux-design" element={<UIUXDetail />} />
          <Route exact path="/web-hosting" element={<WebHostingDetail />} />
          <Route
            exact
            path="/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route
            exact
            path="/software-security"
            element={<SoftwareSecurity />}
          />
          <Route exact path="/blogdetail/:id" element={<BlogDetail />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/start-project" element={<StartProject />} />
          <Route exact path="/our-work" element={<OurWork />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
