import React from "react"
function TechInfo(props){
    const techLogo=[
        {id:"1",title:"Angular.js", logo:"/images/tech-logo/angular.svg"},
        {id:"2",title:"React.js", logo:"/images/tech-logo/react.svg"},
        {id:"3",title:"Node.js", logo:"/images/tech-logo/node.svg"},
        {id:"4",title:"Go", logo:"/images/tech-logo/go.svg"},
        {id:"5",title:"Python", logo:"/images/tech-logo/python.svg"},
        {id:"6",title:"Laravel", logo:"/images/tech-logo/laravel.svg"},
        {id:"7",title:"ASP.Net", logo:"/images/tech-logo/asp.svg"},
        {id:"8",title:"Php", logo:"/images/tech-logo/php.svg"},
        {id:"9",title:"C", logo:"/images/tech-logo/c.svg"},
    ]
    return(
        <>
            <section className="deal_top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 title_bost mb-lg-5">
                            <h2>{props?.title ?? "munish"}</h2>
                            <p>{props?.desc ??"hariom"}</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-9">
                            <ul className="techLogo">
                                {techLogo.map((ele)=>{
                                    return(
                                        <li>
                                            <figure><img src={ele?.logo} alt="" /></figure>
                                            <figcaption>{ele?.title}</figcaption>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
        </section>
        </>
    )
}

export default TechInfo;