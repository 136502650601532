import React from "react";
import { Link } from "react-router-dom";
function StartProjectCard() {
  return (
    <section className="project_card">
      <div className="container">
        <div className="project_card_inner">
          <div className="row">
            <div className="col-md-8 my-auto px-4">
              <h1>
                looking for something specific? We love to work with great
                ideas.
              </h1>
              <Link to="/start-project">Start a Project</Link>
            </div>
            <div className="col-md-4">
              <img src="./images/cartoon.svg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StartProjectCard;
