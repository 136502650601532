import FaqCard from "../AllCards/FaqCard";
import StartProjectCard from "../AllCards/StartProjectCard";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import TechInfo from "../TechInfo";

function WebHostingDetail(){
    return(
        <>
        <AllServicesHeader/>
        <section className="space_top">
            <div className="container">
                <div className="server_sec">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-6 col-xl-5">
                            <div className="ser_cnt">
                                <p>Web hosting, in simple terms, enables your website to be accessible via the Internet. All data visible on your website images, content and code needs to be hosted on a server through a service called as Web Hosting. As a website administrator, you get access to manage your site easily through our state of the art control panel and GUIs.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-5 d-none d-lg-block"><img src="./images/server.svg" className="img-fluid" alt="" /></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="space_top">
            <div className="container">
                <div className="row">
                    <div className="col-md-6"><img src="./images/web2.png" className="img-fluid" alt="" /></div>
                    <div className="col-md-6 my-auto">
                        <div class="create_website">
                            <h2>What is Web Hosting?</h2>
                            <p>Web hosting, in simple terms, enables your website to be accessible via the Internet. All data visible on your website images, content and code needs to be hosted on a server through a service called as Web Hosting. As a website administrator, you get access to manage your site easily through our state of the art control panel and GUIs.</p>
                    </div>
                    </div>
                </div>
                <div className="row pt-lg-5">
                    <div className="col-md-6 my-auto">
                        <div class="create_website">
                            <h2>What is Web Hosting?</h2>
                            <p>Web hosting, in simple terms, enables your website to be accessible via the Internet. All data visible on your website images, content and code needs to be hosted on a server through a service called as Web Hosting. As a website administrator, you get access to manage your site easily through our state of the art control panel and GUIs.</p>
                    </div>
                    </div>
                    <div className="col-md-6"><img src="./web1.avif" className="img-fluid" alt="" /></div>
                </div>
            </div>
        </section>
        <section className="space_top space_bottom">
            <div className="container">
                    <div class="create_website pb-lg-5">
                        <h2>What kind of web hosting do I need?</h2>
                        <p>Based on the nature of your website requirements, you can choose from an array of Linux and Windows hosting plans. For instance, if you need affordable hosting for a basic website, our Windows Essential Plan and Linux Starter Plan are viable options. However, if you have a large portal or would like to host multiple sites, our Business and Pro plans are better suited.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="share_box">
                                <div className="position-relative z-1">
                                    <h3>Shared Hosting</h3>
                                    <p>With shared hosting, your account and websites will share resources with other hosting accounts on that server.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="share_box">
                                <div className="position-relative z-1">
                                    <h3>VPS Hosting</h3>
                                    <p>With shared hosting, your account and websites will share resources with other hosting accounts on that server.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="share_box">
                                <div className="position-relative z-1">
                                    <h3>Dedicated Hosting</h3>
                                    <p>With shared hosting, your account and websites will share resources with other hosting accounts on that server.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        <TechInfo title="Web Hosting" desc="Choosing a development approach is subjective and requires consideration as per the desired user experience, resources, app features, and project timeline. You don’t need any coding experience to start making your own. We are a team of top-notch full stack android developers that has great hands-on experience with Android app development tools and framework. We build native Android applications on Kotlin and Java languages. Apart from this Dart and React Native is also used for Android app development."/>
        <FaqCard/>
        <StartProjectCard/>
        </>
    )
}

export default WebHostingDetail;