import React from "react";
// import img1 from '../../src/clientimages/5.jpg'
// import img2 from '../../src/clientimages/6.jpg'
// import img3 from '../../src/clientimages/7.jpg'
// import img4 from '../../src/clientimages/8.jpg'
// import img5 from '../../src/clientimages/9.jpg'
// import img6 from '../../src/clientimages/client1.jpg'
// import img7 from '../../src/clientimages/client2.jpg'
// import img8 from '../../src/clientimages/client 3.jpg'
// import img9 from '../../src/clientimages/client4.jpg'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const client = [
    { id: 1, src: "./images/cl_logo.svg" },
    { id: 2, src: "./images/cl_logo-1.svg" },
    { id: 3, src: "./images/cl_logo-2.svg" },
    { id: 4, src: "./images/cl_logo-3.svg" },
    { id: 5, src: "./images/cl_logo-4.svg" },
    { id: 6, src: "./images/cl_logo-5.svg" },
    { id: 7, src: "./images/cl_logo-6.svg" },
    { id: 8, src: '../../src/clientimages/8.jpg' },
    { id: 9, src: '../../src/clientimages/5.jpg' },
    { id: 10, src: '../../src/clientimages/9.jpg' },
    { id: 11, src: '../../src/clientimages/6.jpg' },
    { id: 12, src: '../../src/clientimages/7.jpg' },
    { id: 13, src: '../../src/clientimages/client1.jpg' },
    { id: 14, src: '../../src/clientimages/client2.jpg' },
    { id: 15, src: '../../src/clientimages/client3.jpg' },
    { id: 16, src: '../../src/clientimages/client4.jpg' },
   
    

    // img1,img2,img3,img4,img5,img6,img7,img8,img9
  ];
// function OurClient(){
//     const images= [img1,img2,img3,img4,img5,img6,img7,img8,img9]
//     const items = images.map((image, index) => (
//         <img
//             key={index}
//             // src={`image}${Math.floor(
//             //     Math.random() * (300 - 200 + 1) + 200
//             //   )}`}
//             src={image}
//             alt={`Gallery item ${index}`}
//             style={{ width: "100%", borderRadius: "8px" }}
//         />
//     ));
//     return(
//         <>
//         <div className="container">
//             <div className="image">
//                 <ResponsiveMasonry columnsCountBreakPoints={{ 300: 2, 500: 2, 700: 3, 900: 4 }}>
//                     <Masonry> {items}</Masonry>
//                 </ResponsiveMasonry>
//             </div>
//       </div>
//       </>
//     )
// }

// export default OurClient;
// import React from "react"
function OurClient(props){
    // const techLogo=[
    //     {id:"1",title:"Angular.js", logo:"/images/tech-logo/angular.svg"},
    //     {id:"2",title:"React.js", logo:"/images/tech-logo/react.svg"},
    //     {id:"3",title:"Node.js", logo:"/images/tech-logo/node.svg"},
    //     {id:"4",title:"Go", logo:"/images/tech-logo/go.svg"},
    //     {id:"5",title:"Python", logo:"/images/tech-logo/python.svg"},
    //     {id:"6",title:"Laravel", logo:"/images/tech-logo/laravel.svg"},
    //     {id:"7",title:"ASP.Net", logo:"/images/tech-logo/asp.svg"},
    //     {id:"8",title:"Php", logo:"/images/tech-logo/php.svg"},
    //     {id:"9",title:"C", logo:"/images/tech-logo/c.svg"},
    // ]
        // const images= [img1,img2,img3,img4,img5,img6,img7,img8,img9]

    return(
        <>
            {/* <section className="deal_top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 title_bost mb-lg-5">
                            <h2>{props?.title ?? "Our Clients"}</h2>
                            <p>{props?.desc ??""}</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-9">
                            <ul className="techLogo">
                                {images.map((ele)=>{
                                    return(
                                        <li>
                                            <figure><img src={ele} alt="" /></figure>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
        </section> */}
           <section className="our_client">
        <div className="container">
          <div class="team_title text-center mb-lg-5 mb-3">
            <h3>Our Client</h3>
            <p>Let our design thinking guide innovation at your workplace.</p>
          </div>
          <div className="cl_main">
            {client?.map((ele) => {
              return (
                <div className="cl_logo">
                  <img src={ele?.src} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
        </>
    )
}

export default OurClient;