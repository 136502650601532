import React, { useState } from "react";
import "./Contactus.css";
import { Link } from "react-router-dom";

import {
  BsFillTelephoneFill,
  BsGeoAltFill,
  BsEnvelopeAtFill,
  BsChevronRight,
  BsLinkedin,
  BsTwitter,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
export default function Contactus() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      mobile: mobile,
      projectDescription: message,
    };
    try {
      const response = await axios.post(`${apiUrl}/pyndertech/submit`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response.data.status, "contact");
      if (response.data) {
        // navigate(-1);
        toast.success("Form Submitted SuccessFully !", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="containers">
      <section id="contact">
        <h1 className="section-header">Contact Us</h1>
        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            role="form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <div className="col-sm-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="NAME"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12 mt-2">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  placeholder="MOBILE"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </div>
            </div>

            <textarea
              className="form-control mt-2"
              rows="10"
              placeholder="MESSAGE"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>

            <button
              className="btn btn-primary send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              <div className="alt-send-button">
                <i className="fa fa-paper-plane"></i>
                <span className="send-text">SEND</span>
              </div>
            </button>
          </form>

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <i className="fa fa-map-marker fa-2x">
                  {/* <span className="contact-text place">Delhi</span> */}
                  <span className="">
                    B-204, Second Floor, Above SBI Bank, Ansal Chambers-1,
                    Bhikaji Cama Place, New Delhi, Delhi 110066
                  </span>
                </i>
              </li>

              <li className="list-item">
                <BsFillTelephoneFill />
                <span className="contact-text phone">
                  <a href="tel:+919289926105" title="Give me a call">
                    +91-9289926105
                  </a>
                </span>
              </li>

              <li className="list-item">
                {/* <i className="fa fa-envelope fa-2x"> */}
                <BsEnvelopeAtFill />
                <span className="contact-text gmail">
                  <a href="mailto:#" title="Send me an email">
                    enquiry@pyndertech.com
                  </a>
                </span>
                {/* </i> */}
              </li>
            </ul>

            <hr />
            <ul className="social-media-list">
              {/* <li>
                <a href="#" target="_blank" className="contact-icon">
                  <i className="fa fa-github" aria-hidden="true"></i>
                </a>
              </li> */}
              <li>
                <Link to="" className="contact-icon">
                  <BsInstagram />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.facebook.com/pyndertech/"
                  className="contact-icon"
                >
                  <BsFacebook />
                </Link>
              </li>
              <li>
                <Link to="" className="contact-icon">
                  <BsTwitter />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/102766446/admin/feed/posts/"
                  className="contact-icon"
                >
                  <BsLinkedin />
                </Link>
              </li>
              {/* <li >
              <Link to="https://www.linkedin.com/company/102766446/admin/feed/posts/" className='contact-icon'>
                  <BsLinkedin />
                </Link>
              </li>
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li> */}
            </ul>
            <hr />
            <div className="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
          </div>
        </div>
      </section>
    </div>
  );
}
