import React from "react";

export default function Privacy() {
  return (
    <>
      <section className="caarer">
        <div className="container">
          <div className="careTitle">
            <img src="./images/pay_icon.svg" alt="" />
            <h1>Privacy </h1>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi,
              nostrum libero, soluta consequuntur at, minus tenetur
              necessitatibus modi fugiat voluptatum molestias nesciunt placeat.
            </p>
          </div>
        </div>
      </section>
      <section className="space_bottom">
        <div className="container">
          <div className="cnt_box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed
              fugiat eos mollitia perspiciatis quia itaque repellendus pariatur
              cupiditate, amet magni voluptas aliquid corrupti, et aperiam vitae
              quam quod repudiandae! Iste deleniti sed eaque, aliquid animi
              ipsam quam amet, repellat cum magnam nobis officia deserunt sit.
              Quidem recusandae soluta nemo ratione praesentium blanditiis saepe
              ad laborum facere quasi, ipsum quaerat ut quam necessitatibus
              alias nobis nisi molestiae impedit voluptates. Impedit,
              exercitationem.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
              aliquid reprehenderit quidem eius blanditiis voluptatum! Provident
              eveniet quam ut dolores velit, voluptas tempore fugit? Impedit
              quisquam nulla quis possimus ullam.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Accusantium repellat sint magni ducimus dolore maxime eum tenetur
              eveniet fugit expedita? Accusantium quo ipsam consectetur eveniet
              autem modi totam eius quos nihil laudantium? Similique delectus
              officiis neque quibusdam ducimus ut deserunt in voluptatibus odit
              laborum! Necessitatibus ipsam rem veritatis maiores odio, aut
              impedit tenetur corporis dignissimos nulla fuga laudantium
              laboriosam fugiat. Quibusdam nostrum quod consectetur totam
              excepturi soluta autem reiciendis deleniti doloremque ratione
              nobis assumenda alias fugiat quos dolore eaque quia sapiente
              incidunt natus placeat dolor nihil, ipsa magni? Ratione animi,
              quidem modi soluta qui amet tempora voluptatibus veniam mollitia
              maxime molestiae nobis quasi eaque. Asperiores quidem repellat
              tempora quos nisi veniam necessitatibus totam, assumenda, sed
              odit, enim in sint commodi.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. At
              maxime quaerat blanditiis, vero error repudiandae necessitatibus
              similique accusantium cupiditate.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
