import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
function FaqCard(){
    return(
       <>
        <section className='faq'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                    <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                    <Accordion.Header>What domain name should I suppose to choose?</Accordion.Header>
                    <Accordion.Body>
                   <p>Choose a domain name that reflects your brand, business name, or core offering. This helps visitors understand what your website is about at first glance</p>
                   <p>Opt for a domain name that is easy to remember and pronounce. This makes it easier for users to recall and share your website with others.</p> 
                   <p>Shorter domain names are generally easier to type, remember, and share. Aim for a domain that is no longer than 15-18 characters.</p>
                    <p>Including relevant keywords in your domain name can benefit your search engine optimization (SEO) efforts. Keywords can help improve your website's visibility in search engine results related to your industry or offerings.</p>
                    <p>Ensure that the domain name you choose does not infringe on trademarks or copyrights of existing businesses or brands. Conduct a search to verify its availability and legality.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Do you provide facilities for web designing and hosting?</Accordion.Header>
                    <Accordion.Body>
                    "Yes, we offer comprehensive web design and hosting services. Our team specializes in creating stunning websites tailored to your unique needs and preferences. Additionally, we provide reliable hosting solutions to ensure your website is always up and running smoothly. Feel free to contact us to discuss your project in detail and learn more about our services!"

                     <p>This response covers the main points of your service offerings and invites further engagement from potential customers.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Does every website need a database?</Accordion.Header>
                    <Accordion.Body>
                    Not necessarily. Whether a website needs a database depends on its functionality and the nature of the content it serves. Here’s how you could address this question:

"Not every website requires a database. The need for a database typically arises when the website needs to store and retrieve dynamic or structured data. For example, if your website includes user accounts with login credentials, product listings that need to be updated regularly, or content that users can search and filter, then a database would be beneficial. However, if your website is primarily informational with static content that doesn't change frequently, a database may not be necessary. Our team can assess your specific requirements and recommend the best solution tailored to your needs."

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Is my website designed will be mobile-friendly?</Accordion.Header>
                    <Accordion.Body>
                    "Yes, we prioritize creating websites that are fully responsive and mobile-friendly. This means your website will automatically adjust and look great on devices of all sizes, from smartphones to tablets and desktop computers. We use modern design techniques and frameworks that ensure a seamless user experience across different devices. If you have specific concerns or preferences regarding mobile optimization, we can discuss them further to ensure your website meets all your requirements."
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                    </div>
                </div>     

            </div>
        </section>

       </>
    )
}

export default FaqCard