import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function Layout() {
  const location = useLocation();
  console.log(location.pathname, "outlet");
  return (
    <>
      {location.pathname !== "/start-project" && <Header />}
      <Outlet />

      {location.pathname !== "/start-project" && <Footer />}

    </>
  );
}

export default Layout;
