import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
function Blog() {
  const data = [
    {
      id: "2",
      pathname: "/blogdetail/angular",
      serTitle: "Angular",
      date: "2024-07-18",
      title:
        "Angular: Web development framework that is used to build mobile and web applications",
      desc: "Angular is a popular web development framework that is used to build mobile and web applications. It is used to create online experiences that are secure, robust, interactive, and impressive. It uses HTML and TypeScript to create powerful client-side applications.",
    },
    {
      id: "8",
      pathname: "/blogdetail/database",
      serTitle: "Database",
      date: "2024-07-18",
      title: " Database As a Service Cloud-Based Product from Microsoft",
      desc: "Microsoft Azure SQL Database is a cloud-based database solution that brings the power of its predictive engine to help businesses host high-performance applications and databases in the cloud. The power of the Microsoft cloud platform ensures that the remotely hosted backend database has very high uptime, quick access, and low query processing times..",
    },
    {
      id: "3",
      pathname: "/blogdetail/react-next-js",
      serTitle: "React Js",
      date: "November 22, 2022",
      title: "React Js/Next JS: Build Powerful Web Applications",
      desc: "Develop modern web applications with advanced React and Next.js. We deliver high-performance, scalable, and interactive apps that provide an exceptional user experience on both web and mobile platforms.",
    },
    {
      id: "1",
      pathname: "/blogdetail/python-django",
      serTitle: "Python/Django",
      date: "November 22, 2022",
      title: "Python/Django: Build Powerful Web Applications",
      desc: "The Best Website Designing and Development Company in Delhi ensures that the whole technical part is flawlessly managed and cThe Best Website Designing and Development Company in Delhi ensures that the whole technical part is flawlessly managed and c",
    },
    {
      id: "4",
      pathname: "/blogdetail/react-native",
      serTitle: "React Native",
      date: "November 22, 2022",
      title: "React Native: Build Powerful Mobile Applications",
      desc: "Develop cutting-edge mobile applications with advanced React Native. We create high-performance, scalable, and interactive apps that provide an exceptional user experience on both iOS and Android platforms.",
    },

    {
      id: "5",
      pathname: "/blogdetail/java",
      serTitle: "Java",
      date: "November 22, 2022",
      title: "Java: Build Robust Web and Enterprise Applications",
      desc: "Develop robust and scalable web and enterprise applications with Java. Our team ensures flawless technical execution, delivering high-performance solutions tailored to meet your business needs.",
    },
    {
      id: "6",
      pathname: "/blogdetail/devops",
      serTitle: "DevOps",
      date: "November 22, 2022",
      title: "DevOps: Streamline Your Development and Operations",
      desc: "Enhance your development and operations with our comprehensive DevOps solutions. Our expert team ensures seamless integration, continuous delivery, and efficient management of your infrastructure, resulting in faster and more reliable software deployment.",
    },
    {
      id: "7",
      pathname: "/blogdetail/kotlin",
      serTitle: "Kotlin",
      date: "November 22, 2022",
      title: "Kotlin: Modern Solutions for Mobile and Web Development",
      desc: "Leverage Kotlin to build modern, high-performance mobile and web applications. Our expert team delivers seamless, efficient, and innovative solutions that cater to your specific business needs, ensuring a smooth and effective development process.",
    },
  ];
  return (
    <>
      <section className="caarer">
        <div className="container">
          <div className="careTitle">
            <img src="./images/circle_icon.svg" alt="" />
            <h1>Blogs</h1>
            <p>
              Website development involves creating, building, publishing, and
              maintaining a website for the world-wide-web (internet) or private
              network (intranet). Using many open-source available tools, we can
              develop a website with simple static content, or build complex web
              applications, offer services, and run commerce businesses.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {data.map((ele) => {
              return (
                <div className="col-md-6">
                  <div className="blog_list mb-lg-5 mb-4 position-relative">
                    <div className="title">{ele.serTitle}</div>
                    <p className="date">{ele.date}</p>
                    <h2>{ele.title}</h2>
                    <p>{ele.desc}</p>
                    <Link className="stretched-link" to={ele.pathname}>
                      Read Guide <BsArrowRight />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
