import React, { useState, useRef, useEffect } from "react";
import {Link}   from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { BsChevronDown, BsChevronLeft } from "react-icons/bs";

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [currentMenuTitle, setCurrentMenuTitle] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const subMenuRef = useRef(null);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const showSubMenu = (title) => {
    setCurrentMenuTitle(title);
    setSubMenuActive(true);
    if (subMenuRef.current) {
      subMenuRef.current.style.animation = "slideLeft 0.5s ease forwards";
    }
  };

  const hideSubMenu = () => {
    if (subMenuRef.current) {
      subMenuRef.current.style.animation = "slideRight 0.5s ease forwards";
    }
    setTimeout(() => {
      setSubMenuActive(false);
      setCurrentMenuTitle("");
      setActiveSubMenu(null);
    }, 300);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991 && menuActive) {
        toggleMenu();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuActive]);

  const handleSubMenuClick = (title) => {
    setActiveSubMenu(title);
    setTimeout(() => {
      showSubMenu(title);
    }, 200);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row v-center align-content-center">
          <div className="header-item item-left">
            <div className="logo">
              <Link to="/">
                <img src="/images/logo.svg" alt="logo" />
              </Link>
            </div>
          </div>
          <div className="header-item item-center">
            <div
              className={`menu-overlay ${menuActive ? "active" : ""}`}
              onClick={toggleMenu}
            ></div>
            <nav className={`menu ${menuActive ? "active" : ""}`}>
              <div
                className={`mobile-menu-head ${activeSubMenu ? "active" : ""}`}
              >
                <div className="go-back" onClick={hideSubMenu}>
                  <BsChevronLeft />
                </div>
                <div className="current-menu-title">{currentMenuTitle}</div>
                <div className="mobile-menu-close" onClick={toggleMenu}>
                  &times;
                </div>
              </div>
              <ul className="menu-main">
                <li className="menu-item-has-children">
                  <Link
                    to={"/services"}
                    onClick={() => handleSubMenuClick("Services")}
                    onMouseOver={() => handleSubMenuClick("Services")}
                  >
                    Services <BsChevronDown />
                  </Link>
                  {activeSubMenu === "Services" && (
                    <div
                      className={`sub-menu mega-menu mega-menu-column-4 ${
                        subMenuActive ? "active" : ""
                      }`}
                      ref={subMenuRef}
                    >
                      <div className="container d-flex align-items-start">
                        <ul className="serMenu">
                          <li>
                            <Link to="/software-development">
                              Software Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/website-development">
                              Website Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/mobile-development">
                              Mobile App Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-marketing">
                              Digital Marketing
                            </Link>
                          </li>
                          <li>
                            <Link to="/ui-ux-design">UI/UX Design</Link>
                          </li>
                          <li>
                            <Link to="/software-security">
                              Software Security
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-hosting">Web Hosting</Link>
                          </li>
                          <li>
                            <Link to="/crm-system">CRM system</Link>
                          </li>
                        </ul>
                        <div className="d-none d-lg-block">
                          <img src="/images/star_graphic.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li className="menu-item-has-children">
                  <Link
                    to={"/technology"}
                    onClick={() => handleSubMenuClick("Technologies")}
                    onMouseOver={() => handleSubMenuClick("Technologies")}
                  >
                    Technologies <BsChevronDown />
                  </Link>
                  {activeSubMenu === "Technologies" && (
                    <div
                      className={`sub-menu mega-menu mega-menu-column-4 ${
                        subMenuActive ? "active" : ""
                      }`}
                      ref={subMenuRef}
                    >
                      <div className="container d-flex justify-content-between ser_dropmenu">
                        <div className="list-item mainCat">
                          <ul>
                            <li>
                              <Link to="/technology" className="active">
                                <ScrollLink to="1">Mobile App</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="2">Web App</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="3">Ecommerce</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="4">UI/UX</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="5">Game</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="6">Devops</ScrollLink>
                              </Link>
                            </li>
                            <li>
                              <Link to="/technology">
                                <ScrollLink to="7">Cloud</ScrollLink>
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="list-item list-item_2 ">
                          <ul>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/fluter.svg"
                                  alt=""
                                />{" "}
                                Flutter
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img src="/images/menu-icon/react.svg" alt="" />{" "}
                                React Native
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/iconic.svg"
                                  alt=""
                                />{" "}
                                Ionic
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img src="/images/menu-icon/java.svg" alt="" />{" "}
                                Java
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img src="/images/menu-icon/swift.svg" alt="" />{" "}
                                Swift
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img src="/images/menu-icon/xman.svg" alt="" />{" "}
                                Xamarin
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/cordova.svg"
                                  alt=""
                                />{" "}
                                Apache Cordova{" "}
                              </Link>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/native.svg"
                                  alt=""
                                />{" "}
                                Native Script
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/jquery.svg"
                                  alt=""
                                />{" "}
                                jQuery Mobile
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/framework.svg"
                                  alt=""
                                />{" "}
                                Framework7
                              </Link>
                            </li>
                            <li>
                              <Link to="">
                                <img
                                  src="/images/menu-icon/kotlin.svg"
                                  alt=""
                                />{" "}
                                Kotlin Multiplatform Mobile
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/our-work">Our Work</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-item item-right">
            <ul className="hireTalent d-none d-lg-block">
              {/* <li>
                <Link to="">Hire a Talent</Link>
              </li> */}
              <li>
                <Link to="/start-project">Start a Project</Link>
                {/* <a href="mailto:enquiry@pyndertech.com">Start a Project</a> */}
              </li>
            </ul>
            <div className="mobile-menu-trigger" onClick={toggleMenu}>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
