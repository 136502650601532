import React from "react";
import AllServicesHeader from "../ServiceHeader/AllServicesHeader";
import StartProjectCard from "../AllCards/StartProjectCard";
import FaqCard from "../AllCards/FaqCard";
import TechInfo from "../TechInfo";
import KeyPoints from "../KeyPoints";
import OurClient from "../OurClient";

function MobileDetail(){
   return(
        <>
        <AllServicesHeader/>
        <section className="mob_banner">
            <div className="container">
             <img src="./images/mobile_Banner.png" alt="" className="img-fluid" />
            </div>
        </section>
        <section className="mobile_gallery pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 my-auto">
                    <div class="create_website">
                        <h2>Why should I care about Mobile Application development?</h2>
                        <p>mobile app development is not just about creating software for smartphones; it's about leveraging technology to enhance business outcomes, engage customers effectively, drive revenue growth, and adapt to digital transformation. Whether you're a business owner, developer, or enthusiast, understanding the impact and benefits of mobile app development can open doors to new opportunities and empower you to succeed in today's interconnected world</p>
                    </div>
                    </div>
                    <div className="col-md-6">
                        <div className="main_galler">
                            <div className="gallery_item"><img src="./images/img_1.png" className="img-fluid" alt="" /></div>
                            <div className="gallery_item"><img src="./images/img_2.png" className="img-fluid" alt="" /></div>
                            <div className="gallery_item"><img src="./images/img_3.png" className="img-fluid" alt="" /></div>
                            <div className="gallery_item"><img src="./images/img_4.png" className="img-fluid" alt="" /></div>
                            <div className="gallery_item"><img src="./images/img_5.png" className="img-fluid" alt="" /></div>
                            <div className="gallery_item"><img src="./images/img_6.png" className="img-fluid" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <KeyPoints title="Accomplish your business goals with mobile app development" desc="Mobile app development focus to create apps, that are intuitive and accessible. In addition, mobile apps also leverage to deliver multi-channel and omnichannel experience to their users. Our mobile app development has a set of advanced features that delivers the finest user experience."/>
        <section className="pb-lg-5 pb-3">
        <div className="container">
        <div className="row">
                <div className="col-md-6"><img src="./images/mobile_app.png" className="img-fluid" alt="" /></div>
                <div className="col-md-6 my-auto">
                    <div className="cnt">
                        <h3>Why choose Pynder to build a mobile app for your business?</h3>
                        <p>Pyndertech We have collectively published more than Also, we are a top mobile app development agency worldwide and working for the top brands across the globe, for example, Nokia, Accenture, Tech Mahindra, HTC, etc.</p>
                        <p>Our mobile applications are already holding the popularity of businesses and public platforms. Your need is our success, your vision of our innovation and when it blends with our creativity and innovation, then a Webkul mobile application is born.</p>
                        <p>Our services include Android and iOS app development, cross-platform app development, UI and UX consulting, and many more.</p>
                    </div>
                </div>
            </div>
            <div className="row pt-lg-5">
                <div className="col-md-6 my-auto">
                    <div className="cnt pe-5">
                        <h3>Educational App</h3>
                        <p>We can create a web-based system to sell and deliver online courses for educational purposes. With a custom-built platform, you can provide educational materials, content, videos, and files to students and institutions.</p>
                    </div>
                </div>
                <div className="col-md-6"><img src="./images/img2.png" className="img-fluid" alt="" /></div>
            </div>
        </div>
       </section>
        <TechInfo title="Android App Development" desc="Although many open source tools and technologies are for web development such as LAMP (Linux, Apache, MySQL, PHP) stack, WYSIWYG editors, CMS (Content Management System) like WordPress, and Microsoft Visual Studio. But you still require the professional knowledge and expertise of HTML, CSS, and JavaScript, and should also know some basic programming languages to work with."/>
        <FaqCard/>
        <OurClient/>
        <StartProjectCard/>
        
        </>
    )
}

export default MobileDetail;